<template>
  <div class="kecheng">
    <img v-if="imgList.img" class="tui-img" :src="imgList.img" alt="">
    <div class="kmain">
      <kind-choose-view :is-jinpin="true" :show-kc="true" :type="117" :if-img="false" @isOk="isOk" />

      <!-- 最近直播  内容 -->
      <div v-if="list && list.length > 0" class="live">
        <div v-for="(item, index) in list" :key="index" class="item" @click="goPage(item)">
          <!-- 直播时间 -->
          <!-- 活动详情 -->
          <div class="rq">{{ item.clicks ? item.clicks : 0 }}人气值</div>
          <div class="relogo" />
          <img class="item-img" :src="item.img">
          <div class="detail">
            <div class="detail-title">
              <div v-if="item.productLine == 2" class="jp">精品</div>
              <div class="twoEllipsis">{{ item.title }}</div>
            </div>

            <!-- 价格 -->
            <div class="price-box">
              <div class="price"><span class="price-icon">￥</span>{{ item.price }}</div>
              <div class="flex-c">
                <div>{{ dealType(item.types) }} |&nbsp;</div>
                &nbsp;

                <div>&nbsp; {{ Math.floor(item.allTime) }} 课时</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 暂无课程 -->
      <div v-if="!list || list.length == 0" class="nothing">
        <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
        <div class="nothing-text">暂无内容哦~</div>
      </div>
      <div class="flex-jc-c m-t-20">
        <el-pagination :total="total" :current-page="pageNum" :page-sizes="[15, 30, 45, 60]" :page-size="pageSize"
          :pager-count="5" background layout="total, sizes, prev, pager, next" @current-change="handleCurrentChange"
          @size-change="handleSizeChange" />
      </div>
    </div>
  </div>
</template>
<script>
import KindChooseView from '../../components/kindChooseViewjpkc.vue'
import { Know } from '@/api/know'
import { selectKindAllCollage, getSurfaceList } from '@/api/home'
import { getDomain } from '@/api/cookies'
const know = new Know()
export default {
  components: { KindChooseView },
  data() {
    return {
      search: {},
      list: [],
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: null,
      imgList: {},
      domain: null
    }
  },
  async beforeDestroy() {
    this.loading.close()
  },
  created() {
    this.domain = getDomain()
    // 调用轮播图导航接口方法
    this.getList()
    getSurfaceList(4, undefined, this.domain.entrantsType).then((res) => {
      if (res.code == 0) {
        if (res.msg.length > 0) {
          this.imgList = res.msg[0]
        } else {
          this.imgList = {}
        }
      }
    })
  },
  methods: {
    dealType(type) {
      let temp = null
      switch (type) {
        case 1:
          temp = '直播'
          break
        case 2:
          temp = '录播'
          break
        case 3:
          temp = '音频'
          break
        case 4:
          temp = '图文'
          break
        case 5:
          temp = '面授'
          break
        default:
          ''
      }
      return temp
    },
    goPage(item) {
      if (!item) {
        return
      }
      /*  1直播  2 点播  3 音频 4 图文  5 面授  6 课程方向 */
      if (item.types == 1) {
        const routeUrl = this.$router.resolve({
          path: '/zhiboclassxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 2) {
        const routeUrl = this.$router.resolve({
          path: '/videoxq',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 3) {
        const routeUrl = this.$router.resolve({
          path: '/audioCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 4) {
        const routeUrl = this.$router.resolve({
          path: '/imgCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.types == 5) {
        const routeUrl = this.$router.resolve({
          path: '/faceCouser/index',
          query: {
            id: item.id
          }
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    // 获取列表
    async getList() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中',
        spinner: '',
        background: 'rgba(0, 0, 0, 0.2)'
      })
      await know
        .getCourseList(
          this.pageSize,
          this.pageNum,
          this.search.types,
          this.search.school ? this.search.school : undefined,
          this.search.kind ? this.search.kind : undefined,
          this.search.zyId ? this.search.zyId : undefined,
          this.search.kcId ? this.search.kcId : undefined,
          this.search.productLine == 4 ? null : this.search.productLine,
          this.search.areaId ? this.search.areaId : undefined,
          this.search.types ? this.search.types : undefined
        )
        .then((res) => {
          this.total = res.total
          this.list = res.rows
        })
      this.loading.close()
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    searchClick() {
      this.pageSize = 15
      this.pageNum = 1
      this.total = 0
      this.list = []
      this.getList()
    },
    // 筛选
    isOk(val) {
      this.search = val
      this.searchClick()
    },
    // 直播课详情
    zhiboke(item) {
      this.$router.push(`/zhiboclassxq?id=${item.id}&&type=2`)

      /*     router.push({
        name: "直播课详情",
        params: {
          id: item.id,
          type: 2,
        },
      }); */
    }
  }
}
</script>
<style lang="less" scoped>
.tui-img {
  margin: 0;
  width: 100%;
}

/deep/ .kmain {
  width: 1220px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin: 24px auto;
}

/deep/ .box-div {
  border-radius: 8px 8px 8px 8px;
}

/deep/ .zychoice {
  // height: 82px;
  // line-height: 82px;
  border-radius: 8px 8px 8px 8px;
  padding-left: 32px;
}

.kecheng {
  padding-bottom: 24px;
}

// 最近直播  内容
.live {
  // width: 66.2%;
  width: 1220px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 40px;

  .item {
    position: relative;
    margin-bottom: 24px;
    width: 228px;
    // height: 261px;
    // background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    margin-right: 20px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 4px 8px 1px rgba(153, 153, 153, 0.20000000298023224);
    }

    .item-img {
      width: 228px;
      height: 128.25px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      object-fit: cover;
    }

    .boutiqueBan {
      background-image: url('../../assets/img/Home/boutiqueBan.png');
      height: 150px;
      background-size: 100% 100%;

      .detail-title {
        color: #5a4629 !important;
      }

      .tips {
        color: #5a4629 !important;
      }
    }

    .detail {
      padding: 12px 0;

      .boutiqueTxt {
        width: 255px;
        height: 26px;
        padding: 0px 8px;
        line-height: 26px;
        font-size: 12px;
        background: none;
        background-image: url('../../assets/img/Home/boutiqueCoure.png');
        background-size: 100% 100%;
        color: #a57800;

        // padding-left: 83px;
        .mr-5 {
          margin-right: 5px;
        }
      }

      .detail-title {
        width: 228px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        height: 48px;
        display: flex;

        >div:nth-child(2) {
          height: 48px;
          width: 180px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          display: -webkit-box;
        }
      }

      .item-tips {
        height: 20px;
        background: #fff2e9;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        // border: 1px solid #ff7b1c;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ff7b1c;
        line-height: 18px;
        padding: 0px 8px;
      }

      .item-tips+.item-tips {
        margin-left: 8px;
      }

      .tips {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;

        .img {
          width: 12px;
          height: 12px;
          display: inline-block;
        }
      }

      .price-box {
        margin-top: 18px;
        display: flex;
        justify-content: space-between;
        height: 16px;
        line-height: 16px;

        font-family: DIN-Bold;

        .boutiquePrice {
          color: #5a4629 !important;
        }

        .price {
          font-family: Microsoft YaHei-Regular;
          color: #ff5d51;
          font-size: 16px;
          line-height: 18px;
          font-weight: Bold;

          .price-icon {
            font-size: 14px;
            line-height: 14px;
            font-weight: 400;
          }
        }
      }
    }
  }

  .item:nth-child(5n) {
    margin-right: 0;
  }
}

.rq {
  // width: 87px;
  height: 22px;
  background: #000000;
  border-radius: 4px 4px 4px 4px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 8px;
  left: 16px;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  padding-left: 18px;
  padding-right: 4px;
  overflow: hidden;
}

.relogo {
  width: 22px;
  height: 22px;
  background: url('~@/assets/img/homesixth/Group 3261.png');
  opacity: 1;
  position: absolute;
  top: 8px;
  left: 8px;
}

.jp {
  width: 36px;
  height: 20px;
  background: linear-gradient(132deg, #ff9051 0%, #ff5e51 100%);
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  line-height: 20px;
  text-align: center;
  margin-right: 5px;
  margin-top: 2px;
}

.flex-c {
  font-size: 12px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #999999;

  >div:nth-child(2) {
    margin-left: 3px;
  }
}

/deep/ .el-pagination .active {
  background: #1061ff !important;
}

/deep/ .on {
  background: #ecf2ff !important;
  color: #1061ff !important;
}
</style>
